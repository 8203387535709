<template lang="pug">
Layout
  PageHeader(:title="title", :items="items")
  .row.ml-2
    .col-lg-5
      .form-group.mb-3
        label.col-form-label(for="form-control") 主影片
        input.form-control(v-model="setting.youtube_video_uri", type="text", placeholder="請輸入YT完整網址，請勿輸入短網址")
      .radio-group.mt-2
        .radio-group
          b-form-radio-group#radio-group-1(
            v-model="setting.open_chatroom", 
            :options="radio_options", 
            name="radio-options"
            text-field="label"
          )

  .row.ml-2.mt-2
    .col-12
      h5.mt-3.mb-1 第一區塊 － 熱門影片：按照全站影片的熱門程度進行排序
    .col-10
      videoSort(
        v-for="(region,index) in setting.regions" 
        :key="'area' + index" 
        :list="region" 
        :regionIndex="index" 
        @deleteArea="deleteArea" 
        @deleteItem="deleteItem"
      )

  .row.ml-2.mt-3
    .col-12              
      b-button.col-lg-5.add-btn.width-lg.mb-2( variant="outline-primary" @click="addArea()") ＋新增區塊

  .row.text-center.mt-3
    .col
      b-button.width-lg.mr-1(variant="primary", @click="saveSetting" :disabled="saving") {{ saving ? '儲存中...' : '儲存' }}
      b-button.width-lg(variant="primary",@click="releaseSetting" :disabled="releasing") {{ releasing ? '發布中...' : '發布' }}
</template>

<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/Page-header";
import Multiselect from "vue-multiselect";
import DatePicker from "vue2-datepicker";
import draggable from 'vuedraggable'
import videoSort from "@/components/videoSort";

/**
 * Starter component
 */
export default {
  name: 'ProgramHomeSettingEditor',
  data () {
    return {
      title: "專區首頁編輯",
      items: [
        {
          text: "影音節目管理",
          href: "/homeeditor",
        },
        {
          text: "專區首頁編輯",
          active: true,
        }
      ],
      setting: {
        youtube_video_uri: '',
        open_chatroom: null,
        regions: []
      },
      radio_options: [
        { label: "顯示聊天室", value: true },
        { label: "不顯示聊天室", value: false },
      ],
      saving: false,
      releasing: false
    };
  },
  computed: {

  },
  mounted () {

  },
  components: {
    Layout,
    PageHeader,
    Multiselect,
    DatePicker,
    draggable,
    videoSort
  },
  created () {
    this.getProgramPageSetting()
  },
  methods: {
    getProgramPageSetting () {
      let vm = this
      this.$root.apis.getProgramPageSetting(function (_response) {
        vm.setting = _response.body.data
      })
    },
    addArea () {
      const new_item = {
        title: '',
        program: { id: "", name: "" },
        videos: []
      }
      this.setting.regions.push(new_item)
    },
    deleteArea (_index) {
      this.setting.regions.splice(_index, 1)
    },
    deleteItem (_regionIndex, _videoIndex) {
      this.setting.regions[_regionIndex].videos.splice(_videoIndex, 1)
    },
    // 儲存專區設定
    saveSetting () {
      let vm = this
      if (!this.setting.youtube_video_uri) {
        return this.$root.common.showErrorNotify("主影片為必填！")
      }

      if (this.setting.regions.length === 0) {
        return this.$root.common.showErrorNotify("至少需有一個區塊")
      }

      // 整理成需要的格式
      let data = {
        youtube_video_uri: this.setting.youtube_video_uri,
        open_chatroom: this.setting.open_chatroom,
        regions: []
      }

      this.setting.regions.forEach(function (_region, _index) {
        let videos = []
        _region.videos.forEach(function (_video) {
          videos.push(_video.id)
        })

        data.regions[_index] = { id: "", title: "", program_id: "", videos: [] }
        data.regions[_index].videos = videos
        data.regions[_index].program_id = _region.program.id
        data.regions[_index].title = _region.title
        // data.regions[_index].id = (_region.id) ? _region.id : ""
      })

      console.log('=> 整理完的資料:', data)
      this.saving = true
      this.$root.apis.putProgramPageSetting(data, function (_response) {
        vm.$root.common.showSingleNotify("節目首頁設置更新成功！")
        vm.saving = false
      }, function(_error) {
        vm.$root.common.showErrorNotify(_error.body.data)
        vm.saving = false
      })
    },
    // 發布專區設定
    releaseSetting () {
      let vm = this
      this.releasing = true
      this.$root.apis.releaseProgramPageSetting(function (_response) {
        vm.$root.common.showSingleNotify("節目首頁設置發布成功！")
        vm.releasing = false
      }, function (_response) {
        vm.$root.common.showErrorNotify(_response.body.data)
        vm.releasing = false
      })
    }
  }
};
</script>

<style lang="sass" scoped>
  .radio-group
    display: flex
    align-items: center
  .sort-group
    i
      font-size: 1.3rem

</style>
